<template>
  <div>
    <div v-if="num == 0 && teate === false">
      <div class="dis align_center">
        <div class="icon"></div>
        <div class="service">服务机构信息</div>
      </div>
      <div class="dis align_center">
        <img
          src="../../assets/image/icon_commone.png"
          class="img_icon"
          alt=""
        />
        <div class="img_title">
          您的服务机构未进行企业认领，请点击下面按钮进行认领~~
        </div>
      </div>
      <img
        @click="claimsta()"
        src="../../assets/image/claimimg.png"
        class="img"
        alt=""
      />
    </div>

    <div v-else-if="num == 1">
      <div class="dis align_center">
        <div class="icon"></div>
        <div class="service">服务机构信息</div>
      </div>
      <div class="dis align_center">
        <img
          src="../../assets/image/icon_commone.png"
          class="img_icon"
          alt=""
        />
        <div class="img_title">您的服务机构正在进行企业认领，请等待~~</div>
      </div>
      <img src="../../assets/image/claimawait.png" class="img" alt="" />
    </div>

    <div v-else-if="num == 2">
      <div class="dis align_center">
        <div class="icon"></div>
        <div class="service">服务机构信息</div>
      </div>
      <div class="dis align_center pad_20">
        <div>
          <img
            v-if="currency.img === ''"
            src="../../assets/image/topimg.png"
            style="width: 119px; height: 118px"
            alt=""
          />
          <img
            v-else
            :src="currency.img"
            style="width: 119px; height: 118px"
            alt=""
          />
        </div>
        <div class="marl_20">
          <div class="servicetext size_26">{{ currency.name }}</div>
          <div class="flex">
            <div class="servicetext size_18" v-if="currency.audit_status === 2">
              企业认领：已认领
            </div>
            <div class="flex marl_20">
              <div class="servicetext size_18">机构基本信息：</div>
              <div class="servicetext size_18 col_618" @click="amend()">
                点击修改
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="flex servicetext size_18">
              当前服务币余额：
              <div class="col_D3D">{{ currency.currency }}</div>
              个
            </div>
            <div class="servicetext size_18 col_D3D">
              （服务币充值热线：0971—96868）
            </div>
          </div>
        </div>
      </div>

      <el-dialog
        title="机构基本信息"
        :visible.sync="dialogVisible"
        width="600px"
        center
        v-loading="loading"
      >
        <div>
          <div>服务机构头像</div>

          <div class="pad_20">
            <!-- <el-upload ref="uploadfiles2" list-type="picture-card" class="avatar-uploader" action="#"
              accept=".jpg,.jpeg,.png" :multiple="true" :limit="1" name="file2" 
              :file-list="uploadList2"
              :auto-upload="true" :before-upload="httpreq2" :on-change="changeUpload2" :on-exceed="handleExceed"
              :on-remove="handleRemove2">
              <i class="el-icon-plus"></i>
            </el-upload> -->

            <el-upload
              ref="uploadfiles2"
              list-type="picture-card"
              class="avatar-uploader"
              action="#"
              accept=".jpg,.jpeg,.png"
              :multiple="true"
              :limit="1"
              name="file2"
              :auto-upload="true"
              :file-list="uploadList2"
              :http-request="httpreq2"
              :before-upload="changeUpload2"
              :on-exceed="handleExceed"
              :on-remove="handleRemove2"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>

          <div>服务机构介绍</div>
          <el-input
            v-model="service_inp"
            type="textarea"
            :rows="5"
            placeholder="请输入介绍内容"
          ></el-input>
        </div>

        <div>
          <el-button
            style="
              color: #fefefe;
              background-color: #ff6618;
              width: 137px;
              height: 50px;
              margin-top: 20px;
            "
            @click="save()"
            type="text"
          >
            确定保存
          </el-button>
          <el-button
            style="
              color: #fefefe;
              background-color: #cfcfcf;
              width: 137px;
              height: 50px;
              margin-top: 20px;
            "
            type="text"
            @click="cancel()"
          >
            取消
          </el-button>
        </div>
      </el-dialog>
    </div>
    <div v-else-if="num == 3 && teate === false">
      <div class="dis align_center">
        <div class="icon"></div>
        <div class="service">服务机构信息</div>
      </div>
      <div class="dis align_center">
        <img
          src="../../assets/image/icon_commone.png"
          class="img_icon"
          alt=""
        />

        <div class="img_title">您的服务机构认领未通过，请点击重新认领！</div>
      </div>

      <img
        @click="claimsta()"
        src="../../assets/image/claimimg.png"
        class="img"
        alt=""
      />
    </div>

    <div v-if="teate === true" v-loading="loading2">
      <div class="dis align_center">
        <div class="icon"></div>
        <div class="service">服务机构认领</div>
      </div>
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        :rules="rules"
        label-width="120px"
        style="padding-top: 20px"
      >
        <el-form-item label="企业名称 " required prop="name">
          <el-input
            class="width_500"
            placeholder="请输入企业全称"
            v-model="ruleForm.name"
          ></el-input>
          <el-button
            style="margin-left: 30px; background-color: #ff6618; color: #fdfdfd"
            @click="getclaim()"
            >点击认领企业</el-button
          >
        </el-form-item>

        <el-form-item label="企业信用代码 " required prop="num">
          <el-input
            class="width_500"
            placeholder="请输入企业信用代码"
            v-model="ruleForm.num"
          ></el-input>
        </el-form-item>
        <el-form-item label="法人姓名 " required prop="personname">
          <el-input
            class="width_500"
            placeholder="请输入法人姓名"
            v-model="ruleForm.personname"
          ></el-input>
        </el-form-item>
        <div class="cwidth_50">
          <div class="flex">
            <div class="business left">营业执照</div>
            <div class="license">
              *（必须为清晰、完整的彩色原件扫描件或数码照，必须在有效期内且年检章齐全）
            </div>
          </div>
          <div class="mat_30 left">
            <el-upload
              ref="uploadfiles2"
              list-type="picture-card"
              class="avatar-uploader"
              action="#"
              accept=".jpg,.jpeg,.png"
              :multiple="true"
              :limit="1"
              name="file2"
              :auto-upload="true"
              :file-list="uploadList"
              :http-request="httpreq1"
              :before-upload="changeUpload1"
              :on-exceed="handleExceed"
              :on-remove="handleRemove2"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
        </div>
      </el-form>
      <div class="mat_30 exhibitionbox">认领信息展示</div>

      <div class="exhibition" v-if="claimList != ''">
        <div class="pad_10_0">
          <div class="align_center flex pad_10">
            <div class="exhibitiontop text_center">企业名称：</div>
            <div class="flex_1 exhibitiontitle">{{ claimList.name }}</div>
          </div>

          <div class="align_center flex pad_10">
            <div class="exhibitiontop text_center">法定代表人：</div>
            <div class="flex_1 exhibitiontitle">
              {{ claimList.legalPersonName }}
            </div>
          </div>

          <div class="align_center flex pad_10">
            <div class="exhibitiontop text_center">经营状态:</div>
            <div class="flex_1 exhibitiontitle">{{ claimList.regStatus }}</div>
          </div>

          <div class="align_center flex pad_10">
            <div class="exhibitiontop text_center">成立日期：</div>
            <div class="flex_1 exhibitiontitle">
              {{ claimList.estiblishTime }}
            </div>
          </div>

          <div class="align_center flex pad_10">
            <div class="exhibitiontop text_center">注册资本</div>
            <div class="flex_1 exhibitiontitle">{{ claimList.regCapital }}</div>
          </div>
          <div class="align_center flex pad_10">
            <div class="exhibitiontop text_center">实缴资本</div>
            <div class="flex_1 exhibitiontitle">
              {{ claimList.actualCapital }}
            </div>
          </div>

          <div class="align_center flex pad_10">
            <div class="exhibitiontop text_center">统一社会信息代码</div>
            <div class="flex_1 exhibitiontitle">{{ claimList.creditCode }}</div>
          </div>

          <div class="align_center flex pad_10">
            <div class="exhibitiontop text_center">纳税人识别号：</div>
            <div class="flex_1 exhibitiontitle">{{ claimList.taxNumber }}</div>
          </div>

          <div class="align_center flex pad_10">
            <div class="exhibitiontop text_center">企业类型：</div>
            <div class="flex_1 exhibitiontitle">
              {{ claimList.registerType }}
            </div>
          </div>

          <div class="align_center flex pad_10">
            <div class="exhibitiontop text_center">行业</div>
            <div class="flex_1 exhibitiontitle">{{ claimList.industry }}</div>
          </div>

          <div class="align_center flex pad_10">
            <div class="exhibitiontop text_center">核准日期:</div>
            <div class="flex_1 exhibitiontitle">
              {{ claimList.approvedTime }}
            </div>
          </div>

          <div class="align_center flex pad_10">
            <div class="exhibitiontop text_center">参保人数：</div>
            <div class="flex_1 exhibitiontitle">
              {{ claimList.socialStaffNum }}
            </div>
          </div>

          <div class="align_center flex pad_10">
            <div class="exhibitiontop text_center">注册地址：</div>
            <div class="flex_1 exhibitiontitle">
              {{ claimList.regLocation }}
            </div>
          </div>

          <div class="align_center flex pad_10">
            <div class="exhibitiontop text_center">经营范围：</div>
            <div class="flex_1 exhibitiontitle">
              {{ claimList.businessScope }}
            </div>
          </div>
        </div>
      </div>

      <el-button
        style="
          width: 300px;
          height: 50px;
          background: #ff6618;
          border-radius: 10px;
          margin-top: 30px;
          color: #fff;
        "
        @click="getsave()"
        >提交认领申请</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      num: 2,
      // img: "../assets/image/topimg.png",
      teate: false,
      service_inp: "",
      currency: [],
      loads: [],
      loading: false,
      loading2: false,
      loads2: [],
      img: "",
      claimlogin: false,
      uploadList: [],
      uploadList2: [],
      headimg: "",
      claimList: [],
      ruleForm: {
        name: "",
        num: "",
        personname: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入企业全称", trigger: "blur" },
          { required: true, message: "企业全称不能为空", trigger: "change" },
        ],
        num: [
          { required: true, message: "请输入企业信用代码", trigger: "blur" },
          {
            required: true,
            message: "企业信用代码不能为空",
            trigger: "change",
          },
        ],
        personname: [
          { required: true, message: "请输入法人姓名", trigger: "blur" },
          { required: true, message: "法人姓名不能为空", trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.Info();
    this.postcurrency();
    console.log(this.$route.query, "this.$route.query");
  },
  methods: {
    cancel() {
      this.dialogVisible = false;
    },
    amend() {
      this.dialogVisible = true;
      this.headimg = [];
      this.img = [];
    },

    //提交认领
    getsave() {
      if (this.claimlogin === false) {
        this.$message({
          message: "请先认领企业",
          type: "info",
          duration: 1500,
        });
      } else if (this.img === "") {
        this.$message({
          message: "请先上传营业执照",
          type: "info",
          duration: 1500,
        });
      }
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.loading2 = true;
          console.log(valid, "valid");
          let formdata = new FormData();
          console.log(this.loads, "this.loads");
          formdata.append("file", this.loads);
          this.$post("upload_img", formdata).then((res) => {
            this.img = res.address;
            this.getmechanism();
            console.log(this.img, "this.img");
          });
        }
      });
    },
    getmechanism() {
      let data = {
        token: localStorage.eleToken,
        legal_name: this.ruleForm.personname,
        name: this.ruleForm.name,
        credit_code: this.ruleForm.num,
        business_license: this.img,

        // reg_mobile:
      };
      console.log(data, "data");
      this.$post("mechanism_info_save", data).then((res) => {
        console.log(res, "res");
        this.loading2 = false;
        this.$message({
          message: res.msg,
          type: "success",
          duration: 1500,
        });
        this.Info();
      });
    },

    // 点击认领企业
    getclaim() {
      let data = {
        name: this.ruleForm.name,
      };

      this.$post("select_tainyancha", data).then((res) => {
        if (res.status === 1) {
          this.claimList = res.result;

          this.claimlogin = true;
        }

        console.log(res, "res");
      });
    },
    // 上传营业执照
    httpreq1(load) {
      this.loads = load.file;
      console.log(this.loads, "loads");

      // this.$post("upload_img", formdata).then((res) => {
      //   this.img = res.address;
      //   console.log(res, "res");
      // });
    },
    //上传头像
    httpreq2(load) {
      console.log();
      this.loads2 = load.file;
      console.log(this.loads2, "loads2");
      // let formdata = new FormData();
      // formdata.append("file", load.file);
      // this.$post("upload_img", formdata).then((res) => {
      //   this.headimg = res.address;
      //   console.log(res, "res");
      // });
    },
    //修改头像
    save() {
      this.loading = true;
      let formdata = new FormData();
      formdata.append("file", this.loads2);
      this.$post("upload_img", formdata).then((res) => {
        this.headimg = res.address;
        this.headimgupd();
        console.log(res, "res");
      });
    },

    //上传修改头像
    headimgupd() {
      let data = {
        token: localStorage.eleToken,
        img: this.headimg,
        introduce: this.service_inp,
      };
      this.$post("mechanism_upd", data).then((res) => {
        console.log(res, "res");
        this.loading = false;
        this.$message({
          message: res.msg,
          type: "success",
          duration: 1500,
        });
        this.dialogVisible = false;
        this.postcurrency();
      });
    },

    postcurrency() {
      let data = {
        token: localStorage.eleToken,
      };
      this.$post("mechanism_currency", data).then((res) => {
        console.log(res, "res");
        this.currency = res.result;
      });
    },
    handleExceed() {
      this.$message({
        message: "最大上传数量为1张!",
        type: "warning",
        duration: 1500,
      });
    },
    //
    changeUpload1(file, fileList) {
      let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg === "jpg";
      const extension1 = testmsg === "jpeg";
      const extension2 = testmsg === "png";

      if (!extension && !extension1 && !extension2) {
        this.$message({
          message: "上传文件只能是.jpg、.jpeg、.png格式!",
          type: "warning",
          duration: 1500,
        });
        console.log(this.option, " this.option");
        this.img = "";

        this.headimg = "";
        this.uploadList = [];
        return false;
      }
      let formData = new FormData();

      formData.append("file", file.raw);
    },

    changeUpload2(file, fileList) {
      let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg === "jpg";
      const extension1 = testmsg === "jpeg";
      const extension2 = testmsg === "png";

      if (!extension && !extension1 && !extension2) {
        this.$message({
          message: "上传文件只能是.jpg、.jpeg、.png格式!",
          type: "warning",
          duration: 1500,
        });
        console.log(this.option, " this.option");
        this.img = "";

        this.headimg = "";
        this.uploadList2 = [];
        return false;
      }
      let formData = new FormData();

      formData.append("file", file.raw);
    },
    handleRemove2() {
      this.uploadList1 = [];
      this.finance_trade = "";
    },

    //查询认证
    Info() {
      this.teate = false;
      let data = {
        token: localStorage.eleToken,
      };

      this.$post("/mechanism_info", data).then((res) => {
        this.num = res.result.audit_status;
        console.log(this.num, "this.num");
      });
    },

    claimsta() {
      this.teate = true;
      console.log(this.teate, "this.teate");
    },
  },
};
</script>

<style scoped>
@import url("./claim.css");

.width_500 {
  width: 500px;
}

.cwidth_50 {
  width: 50%;
}
</style>
